import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`What is it?`}</h2>
    <PageDescription mdxType="PageDescription">
      <p>{`Respecting and embracing our `}<inlineCode parentName="p">{`differences`}</inlineCode>{` in making every single `}<inlineCode parentName="p">{`experience`}</inlineCode>{` as `}<inlineCode parentName="p">{`delightful`}</inlineCode>{` as possible in any shape or forms we `}<inlineCode parentName="p">{`communicate`}</inlineCode>{` and `}<inlineCode parentName="p">{`serve`}</inlineCode>{` everyone with no exception.`}</p>
      <p>{`Tunggal Design System may be abbreviated as TDS.`}</p>
    </PageDescription>
    <h2>{`Desing Principle`}</h2>
    <ol>
      <li parentName="ol">{`Unity in diversity, adaptive and cohesive in the way we serve people.`}</li>
      <li parentName="ol">{`Trustworthy, building products that people can trust.`}</li>
      <li parentName="ol">{`Considerate, awareness to serve our customers and ourselves well.`}</li>
      <li parentName="ol">{`Generous, serving our customers with great and graceful experiences.`}</li>
      <li parentName="ol">{`Whimsical, bringing joy and simple delights in every part of interaction and experiences.`}</li>
    </ol>
    <h2>{`How and Why`}</h2>
    <p>{`Having our design principles to adapt to everyone’s needs and constraints.
Became a good citizen on any platforms by adopting ideas and platforms languages as guidance to build a suite of great products that engendered trustworthiness and familiarity in every experience.
Maintaining coherence as a means to an end of great experience, forgo the belief that everything should be, look, or works the same in the name of consistency.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      